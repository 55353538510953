div.expand-spinner .spinner-container {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 0px;
}

.draft-spinner .spinner-container img{
    width:15px;
    height:15px;
}
.draft-spinner .spinner-container{
    padding-top: 0px;
}
.ant-spin.ant-spin-spinning.draft-spinner{
    display:  inline-block;;
    justify-content: unset;
    align-items: unset;
    top: 5px;
    position: relative;
    left: 5px;
}

.content-spinner div.spinner-container {
    padding-top: 0px;
}


.loading-message {
    margin: 15px 0;
}

@keyframes spin {
    50% {
        width: 110px;
        height: 110px;
    }
    100% {
        transform: rotate(360deg);
        width: 100px;
        height: 100px;
    }
}
